<template>
  <div></div>
</template>
<script>
import { parseJwt } from '@/service/jwt'
export default {
  async mounted() {
    const query = this.$route.query
    const redirectUri = window.location.origin
    const code = query.code
    const redirect = query.redirect ? decodeURIComponent(query.redirect) : '/'
    try {
      const accessToken = await this.loginByCode({ code, redirectUri })
      const user = parseJwt(accessToken)
      this.$store.commit('SET_TOKEN', { accessToken: accessToken })
      this.$store.commit('SET_USER', user)
      setTimeout(() => {
        this.$router.replace(redirect)
      })
    } catch (error) {
      this.$popErrorPage({
        message: error.message,
        actions: {
          login: true,
        },
      })
    }
  },
  methods: {
    async loginByCode(postParams) {
      const res = await this.$axios.post('/ums/weblogin/token', postParams)
      return res.data.token
    },
  },
}
</script>
